'use client';
import React from 'react';
import { IntercomBubble } from '@mentimeter/intercom';
import { usePageError } from '@mentimeter/errors/usePageError';
import { LoaderIcon } from '@mentimeter/ragnar-visuals';
import { Box, Button, Text } from '@mentimeter/ragnar-ui';

const FiveHundred = ({ error }: { error: Error }) => {
  usePageError({
    error,
    message: 'Something went wrong and got caught in root-error',
    feature: 'root-error',
  });

  return (
    <>
      <IntercomBubble />
      <Box
        alignItems="center"
        justifyContent="center"
        position="absolute"
        width="100%"
        height="100%"
      >
        {error ? (
          <>
            <Text mt="3">Something went wrong...</Text>
            <Button
              size="large"
              mt="1"
              aria-label="Reload page"
              onClick={() => window.location.reload()}
            >
              Click to reload page
            </Button>
          </>
        ) : (
          <LoaderIcon size={5} />
        )}
      </Box>
    </>
  );
};

export default FiveHundred;
