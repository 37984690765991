/* eslint-disable menti-react/filename-convention--jsx */
'use client';

import React, { useState, useEffect, memo } from 'react';
import Script from 'next/script';
import type { Regions, UserResponseT } from '@mentimeter/http-clients';
import { useUser } from '@mentimeter/user';
import { useSubscriptions } from '@mentimeter/billing';
import { prefixUserIdWithUS } from '@mentimeter/region';

function toUnixTimestamp(timestamp: any) {
  return Math.round(Number(new Date(timestamp)) / 1000);
}

interface IntercomArgs {
  user_id: number;
  user_hash: string;
  name: string;
  email: string;
  sso_user: boolean;
  subscription: any;
  created_at: any;
  role: UserResponseT['role'];
  beta_status: UserResponseT['beta_status'];
  region: Regions;
  intercomAppId: string;
  onOpenIntercom: (() => void) | undefined;
  onCloseIntercom: (() => void) | undefined;
}

export const showNewMessage = (message?: string) => {
  try {
    window.Intercom('showNewMessage', message ?? null);
  } catch (_e) {
    // Do nothing
  }
};

const toggleLauncher = (showLauncher = true) => {
  window.Intercom('update', {
    hide_default_launcher: !showLauncher,
  });
};

const initializeIntercom = (args: IntercomArgs, hideLauncher = false) => {
  const {
    onOpenIntercom,
    onCloseIntercom,
    subscription,
    intercomAppId,
    ...user
  } = args;

  const isUSUser = user.region === 'us';
  const adminAppUrl = isUSUser ? 'adminapp-us' : 'adminapp';

  const prefixedUserId = prefixUserIdWithUS({
    userId: user.user_id,
    region: user.region,
  });

  window.Intercom('boot', {
    ...user,
    user_id: prefixedUserId,
    app_id: intercomAppId,
    plan_type: subscription?.plan.name,
    subscription_id: subscription?.subscription_id,
    licenses_purchased: subscription?.licenses_purchased,
    admin_web_link: `https://${adminAppUrl}.mentimeter.com/admin/operations/${user.user_id}/edit`,
    releases_link: `https://metabase.mentimeter.com/question/1462?user_id=${prefixedUserId}`,
    sentry_link: `https://sentry.io/organizations/mentimeter/issues/?project=5925403&query=user.id%3A%22${user.user_id}%22`,
    hide_default_launcher: hideLauncher,
  });
  if (onOpenIntercom) {
    window.Intercom('onShow', () => {
      onOpenIntercom();
    });
  }
  if (onCloseIntercom) {
    window.Intercom('onHide', () => {
      onCloseIntercom();
    });
  }
};

// @ts-expect-error-auto TS(7006) FIXME: Parameter 'fn' implicitly has an 'any' type.
const waitForIntercomScript = (fn) => {
  return setTimeout(fn, 1);
};

// Use to trigger any event triggered message in intercom directly.
// Can be used if we want, for example, to show a specific message to a user
// when he/she does an action on our page.
// Timeout is added to allow the Tracking event to arrived and be registered in
// intercom before checking whetheter there are new message.
const UPDATE_TIMEOUT_MS = 20000;
let timeoutId: ReturnType<typeof setTimeout>;

export const updateIntercom = () => {
  if (timeoutId) {
    clearTimeout(timeoutId);
  }
  timeoutId = setTimeout(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    window && window.Intercom && window.Intercom('update');
  }, UPDATE_TIMEOUT_MS);
};

const INTERCOM_SCRIPT = (intercomAppId: string) => `(function() {
  var w = window;
  var ic = w.Intercom;
  if (typeof ic === "function") {
      ic('reattach_activator');
      ic('update', w.intercomSettings);
  } else {
      var d = document;
      var i = function() {
          i.c(arguments);
      };
      i.q = [];
      i.c = function(args) {
          i.q.push(args);
      };
      w.Intercom = i;
      var l = function() {
          var s = d.createElement('script');
          s.type = 'text/javascript';
          s.async = true;
          s.src = 'https://widget.intercom.io/widget/' + "${intercomAppId}";
          var x = d.getElementsByTagName('script')[0];
          x.parentNode.insertBefore(s, x);
      };
      if (document.readyState === 'complete') {
          l();
      } else if (w.attachEvent) {
          w.attachEvent('onload', l);
      } else {
          w.addEventListener('load', l, false);
      }
  }
})();
`;

export const IntercomScript = ({
  onOpenIntercom,
  onCloseIntercom,
  hideLauncher,
  intercomAppId,
}: {
  onOpenIntercom?: () => void;
  onCloseIntercom?: () => void;
  hideLauncher: boolean;
  intercomAppId: string;
}) => {
  const { user } = useUser();
  const { subscriptions } = useSubscriptions(!user);
  const [inited, setInited] = useState(false);
  useEffect(() => {
    waitForIntercomScript(() => {
      if (window.Intercom && user && !inited) {
        initializeIntercom(
          {
            user_id: user.id,
            // @ts-expect-error-auto TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
            user_hash: user.intercomHash,
            name: user.name,
            email: user.email,
            sso_user: user.hasSso,
            subscription: subscriptions?.current_subscription,
            created_at: toUnixTimestamp(user.createdAt),
            beta_status: user.betaStatus,
            role: user.role,
            // @ts-expect-error-auto TS(2322) FIXME: Type 'Regions | undefined' is not assignable to ty... Remove this comment to see the full error message
            region: user.region,
            intercomAppId,
            onOpenIntercom,
            onCloseIntercom,
          },
          hideLauncher,
        );
        setInited(true);
      }
    });
  }, [
    user,
    subscriptions,
    inited,
    hideLauncher,
    intercomAppId,
    onOpenIntercom,
    onCloseIntercom,
  ]);

  if (!intercomAppId) return null;

  return (
    <Script
      strategy="afterInteractive"
      id="intercom-script"
      dangerouslySetInnerHTML={{
        __html: INTERCOM_SCRIPT(intercomAppId),
      }}
    />
  );
};

export const IntercomVisitorScript = ({
  intercomAppId,
}: {
  intercomAppId: string;
}) => {
  const [initialized, setInitialized] = useState(false);
  useEffect(() => {
    waitForIntercomScript(() => {
      if (window.Intercom && !initialized) {
        window.Intercom('boot', {
          app_id: intercomAppId,
        });
        setInitialized(true);
      }
    });
  }, [initialized, intercomAppId]);

  if (!intercomAppId) return null;

  return (
    <Script
      strategy="afterInteractive"
      id="intercom-visitor-script"
      dangerouslySetInnerHTML={{
        __html: INTERCOM_SCRIPT(intercomAppId),
      }}
    />
  );
};

export const IntercomBubble = memo(() => {
  useEffect(() => {
    if (!window.Intercom) {
      return;
    }
    const timer = waitForIntercomScript(() => toggleLauncher(true));
    return () => {
      clearTimeout(timer);
      waitForIntercomScript(() => toggleLauncher(false));
    };
  });
  return null;
});

export const useDisableIntercomForPage = () => {
  useEffect(() => {
    if (!window.Intercom) {
      return;
    }

    waitForIntercomScript(() => {
      window.Intercom('update', { hide_default_launcher: true });
    });
  });
};
